<template>
  <!-- 一个快讯 -->
  <div>
    <div class="flow-item">
      <div class="item-main">
        <div class="newsflash-item">
          <a
            class="item-title"
            rel="noopener noreferrer"
            target="_blank"
            sensors_operation_list="page_flow"
            style="cursor:pointer"
            @click="showDetail(id,hotNum)"
            >{{ title }}</a
          >
          <div class="item-other">
            <span class="time">{{ time }}</span>
            <span>分享至</span>
            <div class="wechat" @click="dealShare($event,'weixin')">
              <div class="share-code">
                <img
                  class="qrcode"
                  
                  alt="36氪"
                />
              </div>
            </div>
            <a
              class="weibo"
              rel="noopener noreferrer nofollow"
              sensors_operation_list="page_flow"
              target="_blank"
              @click="shareWeibo"
            ></a>
          </div>
          <!-- 快讯原文链接 先隐藏 -->
          <!-- <div class="item-desc">
            <span>{{ content }}</span>
            <a
              class="link"
              sensors_operation_list="page_flow"
              rel="noopener noreferrer nofollow"
              href="javaScript:void(0)"
              @click="showDetail(id,hotNum)"
              ><span></span>原文链接</a
            >
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {updateHotnumFn} from '@/js/common/index' ;
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    // content: {
    //   type: String,
    //   required: true,
    // },
    time: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: false,
    },
    id: {
      type: Number,
      required: true,
    },
    list:{
      type: Array,
      required: true,
    },
    // lowerid:{
    //   type: Number,
    //   required: true,
    // },
    hotNum:{
      type:Number,
      required: true,
    }
  },
  methods: {
    showDetail(id,hotNum) {
      // 就list列表里的id 随机生成不等于当前id的  下一篇快讯的id
      console.log("this.list.length: ",this.list.length)
      console.log("this.hotNum: ",this.hotNum)
      try{
         for(let i = 0;i<this.list.length;i++){
            let rand = Math.ceil(Math.random()*this.list.length-1)
            if(id != this.list[rand].id){
              this.lowerid = this.list[rand].id;
              // console.log("randID",this.lowerid)
              throw new Error('end');
            }
          }   
      }catch(e){
        if(e.message !== 'end'){
          throw e;
        }
      }
      
      // console.log("fffff： ",hotNum)
      updateHotnumFn(id,hotNum);
      this.$router.push({
        path: "/newsFlashDetail",
        query: {
          id: this.id,
          lowerid:this.lowerid
        },
      });
    },
    shareWeibo(){
      window.open(
          "http://service.weibo.com/share/share.php?url=" +
            encodeURIComponent(window.location.href) +
            "&title=" +
            this.title +
            "&pic=" +
            +
            "&appkey=2706825840"
        );
    },
    dealShare(event, type) {
        
      console.log("weixin type = "+type)

      //设置分享内容
      bShare.addEntry({
        title: this.title,
        url: window.location.href,
        summary: "这篇文章不错 分享一下~",
        pic: ''
      });

      if(type != "more"){
        //开始分享
        bShare.share(event, type, 0)
        return false
      }else{
        bShare.more(event);
        return false
      }

    }
  },
};
</script>

<style>
.flow-item {
  margin-bottom: 40px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  text-align: left;
}
.flow-item .item-main {
  width: 630px;
}
.newsflash-item {
  width: 100%;
}
.newsflash-item .item-title {
  display: block;
  line-height: 25px;
  font-size: 18px;
  font-weight: 700;
  color: #262626;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.newsflash-item .item-title:visited {
  color: #787878;
}
.newsflash-item .item-other {
  height: 20px;
  line-height: 20px;
  margin: 6px 0 10px;
  font-size: 12px;
  color: #a7a7a7;
}
.newsflash-item .item-other .time {
  margin-right: 20px;
}
.newsflash-item .item-other > * {
  float: left;
  margin-right: 8px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.newsflash-item .item-other .wechat {
  position: relative;
  height: 20px;
  width: 20px;
  background: url(//static.36krcdn.com/36kr-web/static/wechat-pray.28ebe3d1.png)
    no-repeat 50%;
  background-size: cover;
  cursor: default;
}
.newsflash-item .item-other .wechat .share-code {
  visibility: hidden;
  position: absolute;
  z-index: 1001;
  top: -88px;
  left: -20px;
  height: 80px;
  width: 240px;
  line-height: 20px;
  padding: 10px 10px 10px 80px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 2px #ddd;
  font-size: 13px;
  color: #262626;
  -webkit-transition: visibility 0.2s;
  transition: visibility 0.2s;
}
.newsflash-item .item-other .wechat .share-code .qrcode {
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  width: 80px;
}
.newsflash-item .item-other .wechat .share-code:after {
  content: " ";
  position: absolute;
  bottom: -5px;
  left: 25px;
  height: 10px;
  width: 10px;
  background-color: #fff;
  box-shadow: 1px 1px 2px #ddd;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.newsflash-item .item-other .weibo {
  height: 20px;
  width: 20px;
  background: url(//static.36krcdn.com/36kr-web/static/weibo-pray.55864b4a.png)
    no-repeat 50%;
  background-size: cover;
  cursor: pointer;
}
.newsflash-item .item-other > * {
  float: left;
  margin-right: 8px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
a {
  text-decoration: none;
}
.newsflash-item .item-desc {
  line-height: 24px;
  font-size: 14px;
  color: #787878;
}
.newsflash-item .item-desc .link {
  display: inline-block;
  color: #4d7fd0;
  margin-left: 10px;

  /* background-size: 14px; */
}
.newsflash-item .item-desc .link span {
  display: inline-block;
  height: 24px;
  width: 24px;
  background-image: url(~@/assets/icon/icon.png);
  background-position-x: 567px;
  background-position-y: -439px;
  vertical-align: middle;
}
</style>
