<template>
  <!-- 搜索-快讯列表页-->
  <div class="container">
    <!-- 文章列表 -->
    <div class="list" v-if="list.length!=0">
      <div v-for="(news,index) in list" :key="news.id">
        <!-- :content="news.content" -->
        <V-OneNewsFlash
          :title="news.title"
          :list="list"
          :hotNum="news.hotNum"
          :time="news.time"
          :id="news.id"
          :lowerid="lowerid"
        ></V-OneNewsFlash>
        <hr v-if="index+1!=list.length" />
      </div>
    </div>
    <div v-if="list.length==0" style="font-size: 20px;margin-top:100px; color:#626262">抱歉未找到相关结果</div>
    <br />
    <!-- 分页 -->
    <div class="page-nav">
      <el-pagination
        background
        layout="prev, pager, next"
        :hide-on-single-page="hidePageNav"
        :total="totlePage"
        :page-size="pageSize"
        @prev-click="prevPage"
        @next-click="nextPage"
        @current-change="currentPage"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import OneNewsFlash from "@/components/one-news-flash/index.vue";
import { showTimeFormat } from "@/js/common/index.js";
import { DELETE, QUERY } from "@/services/dao.js";
export default {
  data() {
    return {
      hidePageNav: true,
      pageNum: 0,
      pageSize: 12,
      totlePage: 0,
      list: [],
      lowerid: 0 //下一篇文章的ID
    };
  },
  created: function() {
    this.init();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query.searchWord != from.query.searchWord) {
        this.init(); //重新加载数据
      }
    }
  },
  methods: {
    //初始化
    init() {
      this.searchWord = this.$route.query.searchWord;
      this.searchInfo(this.pageSize, 0);
    },
    //前一页
    prevPage(val) {
      this.pageNum = val - 1;
      this.searchInfo(this.pageSize, this.pageSize * this.pageNum);
    },
    //下一页
    nextPage(val) {
      this.pageNum = val - 1;
      this.searchInfo(this.pageSize, this.pageSize * this.pageNum);
    },
    //当前页
    currentPage(val) {
      this.pageNum = val - 1;
      this.searchInfo(this.pageSize, this.pageSize * this.pageNum);
    },
    async searchInfo(limit, offest) {
      let info = null;
      if (this.searchWord == null || this.searchWord == "") {
        info = await QUERY(
          "post",
          "",
          "News(limit:" +
            limit +
            ", offset:" +
            offest +
            " ) {    id    createTime    content        commentNum    collectNum    browseNum    authorName    accessory    img    intro        praiseNum    reference        title        hotNum  }  News_aggregate {    aggregate {      count    }  }"
        );
      } else {
        info = await QUERY(
          "post",
          "",
          "News(limit:" +
            limit +
            ", offset:" +
            offest +
            ',  where: {title: {_like: "%' +
            this.searchWord +
            '%"}}) {    id    createTime    content        commentNum    collectNum    browseNum    authorName    accessory    img    intro        praiseNum    reference        title        hotNum  }  News_aggregate(where: {title: {_like: "%' +
            this.searchWord +
            '%"}}) {    aggregate {      count    }  }'
        );
      }
      this.list.splice(0, this.list.length);
      for (let i = 0; i < info.data.News.length; i++) {
        this.list.push({
          id: info.data.News[i].id*1,
          title: info.data.News[i].title,
        //   content: info.data.News[i].intro,
          time: showTimeFormat(info.data.News[i].createTime),
          hotNum: info.data.News[i].hotNum
        });
      }
      this.totlePage = info.data.News_aggregate.aggregate.count;
    }
  },
  components: {
    "V-OneNewsFlash": OneNewsFlash
  }
};
</script>

<style scoped>
.container .list {
  width: 633px;
  display: inline-block;
  padding: 20px;
  background-color: #ffff;
}
.page-nav {
  display: inline-block;
  width: 800px;
  margin-top: 30px;
}
</style>